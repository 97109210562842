import FinancialCategoriesList from "../../components/FinancialCategory/FinancialCategoryList";
import MainLayout from "../../components/Layout/MainLayout";


const FinancialCategory = () => {

  return (
      <FinancialCategoriesList />
  );
};

export default FinancialCategory;
